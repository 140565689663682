import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Validation from './validation'
import { handleStoreUser } from 'services/api/users'

import Component from './Component'

const Page = () => {

    const [messages, setMessages] = useState({ messages: [], alert: '' })
    const history = useHistory()
    const formik = useFormik({
        initialValues: { name: '', email: '', document: '', roles: '', search: '', contact: '', value: '0', searchOrigin: '' },
        validationSchema: Yup.object(Validation(Yup)),
        onSubmit: () => {
            const formattedData = {...formik.values, 'document': formik.values.document.replace(/\D/g, '')}
            handleStoreUser(history, formattedData, setMessages);
        }
    })

    return <Component formik={formik} messages={messages} />
}

export default Page