import { Label, InputLabel } from './styled'
import {applyDocumentMask} from '../../utils/applyDocumentMask'

const Component = ({
  type,
  name,
  label,
  flexGrow,
  readOnly,
  variant,
  formik,
  placeholder,
  labelColor,
  mask,
  value,
}) => {
  return (
    <>
      <InputLabel className={flexGrow ? flexGrow : null}>
        <Label color={labelColor}>
          <label htmlFor={name}>{label}</label>
          <div>
            {formik.touched[name] && formik.errors[name]
              ? formik.errors[name]
              : null}
          </div>
        </Label>
        {readOnly ? (
          <input
            id={name}
            name={name}
            className={variant}
            value={value}
            disabled
            style={{ opacity: 0.7 }}
          />
        ) : (
          <input
            type={!type ? 'text' : type}
            id={name}
            name={name}
            readOnly={readOnly}
            className={variant}
            placeholder={placeholder}
            value={name === "document" ? applyDocumentMask(formik.values[name]) : (mask ? mask(formik.values[name]) : formik.values[name])}
            // value={mask ? mask(formik.values[name]) : formik.values[name]}
            onChange={(data) => { 
              if(name === 'document') {
                const { value } = data.target;
                const formattedValue = value.slice(0, 18);
                data.target.value = formattedValue;
                formik.handleChange(data);

              } else formik.handleChange(data)}}
          />
        )}
      </InputLabel>
    </>
  )
}

export default Component
