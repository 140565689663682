import {cpfMask, cnpjMask} from './index'

export const applyDocumentMask = value => {
    if (value == null) {
      return '';
    }

    const unmaskedValue = value.replace(/\D/g, '');
  
    if (unmaskedValue.length <= 11) {
      return cpfMask(unmaskedValue);
    } else {
      return cnpjMask(unmaskedValue);
    }
  }