import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import BtnOrange from 'components/Button/BtnOrange'
import BtnRed from 'components/Button/BtnRed'
import BtnBox from 'components/Button/BtnBox'
import BtnWhite from 'components/Button/BtnWhite'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import Modal from 'components/Modal'
import Select from 'components/Form/LabelSelect'
import Checkbox from 'components/Form/LabelCheckbox'
import { phoneMask, moneyMask } from '../../utils/index'
import { useEffect, useState } from 'react'
import CardBody from 'components/CardBody'
import { handleGeneratePassword } from 'services/api/users'
import { handleStatusByFirm } from 'services/api/status'
import { handleOriginsByFirm } from 'services/api/origins'
import { handleCompanies } from 'services/api/empresas'
import { translate } from '../../utils/translate';

const Component = props => {
  const [data, setData] = useState([])
  const [originData, setOriginData] = useState([])

  const [companiesData, setCompaniesData] = useState([])

  useEffect(() => {
    ;(async () => {
      setCompaniesData(await handleCompanies())
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setData(await handleStatusByFirm())
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setOriginData(await handleOriginsByFirm())
    })()
  }, [])

  return (
    <>
      <TitleBar label={translate('pages.UsersUpdate.users')} currentPage="" />
      <Content>
        <Card>
          <CardTitle title={translate('pages.UsersUpdate.edit')}>
            <BtnOrange onClick={() => props.setVisibleModal(true)}>
            {translate('pages.UsersUpdate.delete')}
            </BtnOrange>
          </CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={props.formik.handleSubmit}>
                <Messages
                  formMessages={props.messages.messages}
                  alert={props.messages.alert}
                />
                <Input name="name" label={translate('pages.UsersUpdate.name')} formik={props.formik} />
                <Input name="email" label={translate('pages.UsersUpdate.email')} formik={props.formik} />
                <Input
                  name="document"
                  label={translate('pages.UsersStore.document')}
                  formik={props.formik}
                />
                <Input
                  name="contact"
                  label={translate('pages.UsersUpdate.contact')}
                  mask={phoneMask}
                  formik={props.formik}
                />
                <Checkbox
                  name="text_message"
                  label={translate('pages.UsersUpdate.sendTextMessage')}
                  formik={props.formik}
                  checked={data.text_message}
                  onChange={e => {
                    props.formik.setFieldValue('text_message', e.target.checked)
                  }}
                />
                <Checkbox
                  name="voice_message"
                  label={translate('pages.UsersUpdate.sendVoiceMessage')}
                  formik={props.formik}
                  checked={data.voice_message}
                  onChange={e => {
                    props.formik.setFieldValue(
                      'voice_message',
                      e.target.checked,
                    )
                  }}
                />
                <Input
                  name="value"
                  label={translate('pages.UsersUpdate.value')}
                  mask={moneyMask}
                  formik={props.formik}
                />
                <Select name="roles" label={translate('pages.UsersUpdate.permission')} formik={props.formik}>
                  <option value="">{translate('pages.UsersUpdate.select')}</option>
                  <option value="admin">{translate('pages.UsersUpdate.admin')}</option>
                  <option value="user">{translate('pages.UsersUpdate.user')}</option>
                </Select>
                <Select name="status_id" label={translate('pages.UsersUpdate.status')} formik={props.formik}>
                  <option value="">{translate('pages.UsersUpdate.select')}</option>
                  {data.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select name="origin_id" label={translate('pages.UsersUpdate.origin')} formik={props.formik}>
                  <option value="">{translate('pages.UsersUpdate.select')}</option>
                  {originData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select name="company_id" label={translate('pages.UsersUpdate.company')} formik={props.formik}>
                  <option value="">{translate('pages.UsersUpdate.select')}</option>
                  {companiesData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <BtnBox>
                  <BtnBlue type="submit" disabled={props.formik.isSubmitting}>
                    {props.formik.isSubmitting ? <Spinner /> : translate('pages.UsersUpdate.submit')}
                  </BtnBlue>

                  <span
                    onClick={async () => {
                      await handleGeneratePassword(props.id)
                      props.history.push(`/usuarios`)
                    }}
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                  >
                    {translate('pages.UsersUpdate.generatePassword')}
                  </span>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>
      <Modal
        visibleModal={props.visibleModal}
        setVisibleModal={props.setVisibleModal}
      >
        <Card>
          <Content>
            <Content>
              <CardBody>
                <p>{translate('pages.UsersUpdate.confirmDelete')}</p>
                <BtnBox>
                  <BtnRed onClick={props.handleDelete}>{translate('pages.UsersUpdate.delete')}</BtnRed>
                  <BtnWhite onClick={() => props.setVisibleModal(false)}>
                    Cancelar
                  </BtnWhite>
                </BtnBox>
              </CardBody>
            </Content>
          </Content>
        </Card>
      </Modal>
    </>
  )
}

export default Component
